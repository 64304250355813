
@import '../variables';
@import '../functions';
@import '../mixins/direction';

.sports_flex-container {
    display: grid;
    -ms-grid-columns: 50% 50%;
    grid-template-columns: [gallery] calc(50% - 80px) [info] calc(50% - 20px);
    grid-template-rows: auto auto auto auto auto;
    grid-column-gap: 60px  
}
.flex-item-left {
    display: inline-block;
    border-radius: 2px;
    width: 90%;
    .flex-item-image_container{
        box-shadow: 0 0 0 2px #f2f2f2 inset;
    }
    img {
        padding: 30px;
        width: 100%;
        height: auto;
    }
}
.flex-item-right {
    position: relative;
    width: 680px;

    .sports_meta {
        list-style: none;
        margin: 12px 0 0;
        padding: 12px 0 0;
        display: flex;
        flex-wrap: wrap;
        color: #999;
        font-size: 14px;

        .sports_meta_container {
            margin-bottom: 20px;
            height: 100%;
            width: 100%;
            .sports_ul_container {
                display: flex;
                flex-direction: row;
                margin-bottom: 2px;
                background-color: $light-opposite-color;
                padding: 4px;
                .sports__meta_ul_left{
                    font-size: 16px;
                    padding: 3px;
                    flex:2;
                   .sports__meta_ul_data_time {
                    font-size: 12px;
                    margin-top: 10px
                    }                  
                }
                .sports__meta_ul_right{     
                    padding: 10px;
                    flex:3;
                    .sports__meta_ul_data{
                        width: 100%;
                    .sports__meta_data_li{
                        margin: 12px;
                        }
                    }                          
                }
            }
        }
        .sports__meta_ul {
            margin-right: 45px;
            height: 12px;
            padding:5px;
            .sports__meta_li{
                padding: 2px;
                color: $light-color;
            }   
            .sports__meta_li{
                padding:2px;
            }      
        }
        .sports__meta_ul_data {
            margin: 12px;
            width: 100%;
            color: $light-opposite-text-color;       
                .sports__meta_data_li {
                    height: 10px;
                }          
            span {
                color: rgb(232, 62, 62);
                padding: 15px;
            }
        }
        ul {
            list-style: none;
            display: flex;
            flex-wrap: wrap;
        }
    }
    p {
        width: 400px;
        font-size: 18px;
        border-bottom: 1px solid #ebebeb;
    }
}
@media (max-width: breakpoint(lg-end)) and (min-width: breakpoint(sm-start)) {
    .sports_flex-container {
        display: flex;
        flex-direction: column;
        .flex-item-left {
            box-shadow: 0 0 0 2px #f2f2f2 inset;
            display: inline-block;
            border-radius: 2px;
            width: 0%;    
        img {
            padding: 5px;
            width: 100%;
            height: auto;
            }
        }
    }
}

/*
// .site
*/
@import '../variables';

.site {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}
.site__header {
    flex-shrink: 0;
}
.site__body {
    flex-grow: 1;

    .flex-container {
        display: grid;
        -ms-grid-columns: 50% 50%;
        grid-template-columns: [gallery] calc(50% - 80px) [info] calc(50% - 20px);
        grid-template-rows: auto auto auto auto auto;
        grid-column-gap: 60px;
        
    }
}
.site__footer {
    flex-shrink: 0;
}

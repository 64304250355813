@import '../variables';
@import '../functions';
@import '../mixins/direction';

    .livecasino_flex-container{
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 50% 50%;
        grid-template-columns: [gallery] calc(50% - 80px) [info] calc(50% - 20px);
        grid-template-rows: auto auto auto auto auto;
        grid-column-gap: 60px;

        .flex-item-left-livecasino {
            display: inline-block;
            border-radius: 2px;
            width: 90%;
        .flex-item-image_container{
            box-shadow: 0 0 0 2px #f2f2f2 inset;
            }       
            img {
                padding: 30px;
                width: 100%;
                height: auto;
            }
        }

        .flex-item-right-livecasino {
            position: relative;
            width: 680px;  
            p {
                width: 100%;
                font-size: 16px;
            }
            
        }
        

    }
    .button{
        appearance: none;
        margin-left: 0px;
        width:192px;
        height:55px;
        font-size: 32px;
        font-weight: 700;
        margin-top: 20px;
        margin-bottom: 20px;
        border-radius: 4px ;
    
    }
    
    @media (max-width: breakpoint(lg-end)) and (min-width: breakpoint(sm-start)) {
        .livecasino_flex-container {
            display: flex;
            flex-direction: column;
            .flex-item-left-livecasino {
                box-shadow: 0 0 0 2px #f2f2f2 inset;
                display: inline-block;
                border-radius: 2px;
                width: 0%;    
            img {
                padding: 5px;
                width: 100%;
                height: auto;
                }
            }
        }
    }



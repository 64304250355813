/*
// .block-banner
*/
@import '../variables';
@import '../functions';

.block-banner {
}
.block-banner__body {
    position: relative;
    display: block;
    height: 170px;
    text-align: center;
    color: inherit;

    &:hover {
        color: inherit;
    }
}
.block-banner__image {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    z-index: -1;
}
.block-banner__image--mobile {
    display: none;
}
.block-banner__title {
    padding-top: 28px;
    font-size: 30px;
    line-height: 1;
    font-weight: $font-weight-bold;
}
.block-banner__text {
    padding-top: 8px;
}
.block-banner__button {
    padding-top: 20px;
}
.block-banner__mobile-br {
    display: none;
}

@media (max-width: breakpoint(sm-end)) {
    .block-banner__body {
        height: 390px;
    }
    .block-banner__image--desktop {
        display: none;
    }
    .block-banner__image--mobile {
        display: block;
    }
    .block-banner__title,
    .block-banner__text,
    .block-banner__button {
        width: 220px;
        margin-left: 5px;
        margin-right: auto;
    }
    .block-banner__title {
        padding-top: 48px;
        line-height: 1.1;
    }
    .block-banner__text {
        padding-top: 12px;
    }
    .block-banner__button {
        padding-top: 28px;
    }
    .block-banner__mobile-br {
        display: block;
    }
}

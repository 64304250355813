/*
// .indicator
*/
@import '../variables';
@import '../functions';
@import '../mixins/direction';

$local-open-transition-duration: 0.2s;

.indicator {
    display: flex;
    color: $indicator-default-font-color;
}
.indicator__button {
    display: flex;
    align-items: center;
    border: none;
    background: transparent;
    cursor: pointer;
    padding: 0;
    color: inherit;
    fill: currentColor;
    

    &:focus {
        outline: none;
    }
    &:hover {
        color: inherit;
    }
}
.indicator__area {
    background: $indicator-default-bg;
    transition: background-color 0.2s;
    border-radius: $indicator-border-radius;
    height: $indicator-height;
    padding: 0 14px;
    display: flex;
    align-items: center;
}
.indicator__value {
    height: 15px;
    font-size: 10px;
    padding: 0 5px;
    border-radius: 1000px;
    position: relative;
    top: -11px;
    background: $indicator-default-counter-bg;
    color: $indicator-default-counter-font-color;
    font-weight: $font-weight-bold;

    @include direction {
        #{$margin-inline-start}: -5px;
        #{$margin-inline-end}: -8px;
    }
}
.indicator__dropdown {
    top: 100%;
    position: absolute;
    visibility: hidden;
    transform-origin: top;
    transform: rotateX(60deg);
    opacity: 0;
    transition: transform $local-open-transition-duration, opacity $local-open-transition-duration,
        visibility 0s $local-open-transition-duration;

    @include direction {
        #{$inset-inline-end}: 0;
    }
}

.indicator--trigger--click.indicator--opened,
.indicator:hover {
    .indicator__button {
        color: $indicator-hover-font-color;
    }
    .indicator__area {
        background: $indicator-hover-bg;
        transition-duration: 0.1s;
    }
    .indicator__value {
        background: $indicator-hover-counter-bg;
        color: $indicator-hover-counter-font-color;
    }
}

.indicator--trigger--click.indicator--opened,
.indicator--trigger--hover:hover {
    .indicator__dropdown {
        z-index: 1;
        transition-delay: 0s, 0s, 0s;
        opacity: 1;
        visibility: visible;
        transform: rotateX(0deg);
    }
}

.indicator__icon {
    transition: transform 0.2s, opacity 0.2s;
}
.indicator__icon--open {
    position: absolute;
}
.indicator--trigger--click:not(.indicator--opened) {
    .indicator__icon--open {
        transform: scale(0);
        opacity: 0;
    }
}
.indicator--trigger--click.indicator--opened {
    .indicator__icon:not(.indicator__icon--open) {
        transform: scale(0);
        opacity: 0;
    }
}

.indicator--mobile {
    .indicator__area {
        height: 36px;
        padding: 0 10px;
    }
}

@media (min-width: breakpoint(lg-start)) and (max-width: breakpoint(lg-end)) {
    .indicator__area {
        padding: 0 12px;
    }
}

/*
// .products-view
*/
@import '../variables';

.products-view {
}
.products-view__options {
    padding-bottom: 20px;
}
.products-view__pagination {
    padding-top: 30px;
}

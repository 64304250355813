/*
// .badge
*/
@import '../variables';

.badge {
    border-radius: 1.5px;
    font-weight: $font-weight-medium;
    padding: 0.25em 0.5em;
}

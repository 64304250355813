/*
// .topbar
*/
@import '../variables';
@import '../mixins/direction';

$local-item-margin: 0;

.topbar {
    height: $topbar-height;
    background: $topbar-bg;
    box-shadow: $topbar-shadow;
    border-bottom: $topbar-border-bottom;
    font-size: 14px;
    line-height: 14px;
    color: $topbar-font-color;
    position: relative;
    z-index: 20;
}
.topbar__container {
    height: 100%;
}
.topbar__row {
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0 #{-$local-item-margin};
}
.topbar__item {
    margin: 0 $local-item-margin;
    height: 100%;
    display: flex;
    align-items: center;
}
.topbar__item-value {
    color: $topbar-dropdown-value-color;
    transition: all 0.1s;
}
.topbar__spring {
    flex-grow: 1;
}
.topbar__item--link + .topbar__item--link {
    @include direction {
        #{$margin-inline-start}: 16px;
    }
}
